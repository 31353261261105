import {Component, useEffect, useState} from "react";

import {Button, Card, ConfigProvider, Flex, Form, Input, Layout, message, Row, Select, Upload} from "antd";
import Apiurl from "../ConstURL";

import {
    FileExcelOutlined, InboxOutlined,
} from '@ant-design/icons';

import Header from "../components/Header";
import {useAuth} from "../funcs/Authcontext";
import axios, {options} from "axios";



export default function Addtask() {
    const [form] = Form.useForm()

    const [fileList, setFileList] = useState([]);

   const beforeUpload = (file) => {
        const isTXT= file.type === 'text/plain';
        if (!isTXT) {
            message.error(`${file.name} это не txt`);
        }
       return isTXT || Upload.LIST_IGNORE;
    };
    const normFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            let newFileList = [...e.fileList];
            // newFileList = newFileList.slice(-1);
            return newFileList;
        }
        let newFileList = [...e.fileList];
        // newFileList = newFileList.slice(-1);
        if (e.fileList.length>0){
            newFileList[0]["status"]="done";
        }

        return newFileList;
    };
    const onChangeupload = (info) => {
        let newFileList = [...info.fileList];
        setFileList(newFileList);
        console.log(newFileList);
    };

    const Sendrequest = (values)=>{
        const data= new FormData()
        data.append('taskfile', values.dragger[0].originFileObj)
        const config= {
            "headers": {
                "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
            }
        }
        axios.post(Apiurl+"/task/create?token="+localStorage.getItem("token"), data,config).then((res: any) => {
            // options.onSuccess(res.data, options.file)
            console.log(res.data);
            if (res.data.Created){
                message.success("Задача добавлена!\nЕе id: "+res.data.Taskid, 9)
            }else {
                message.error("Задача не добавлена! Ошибка", 9)
            }

        }).catch((err: Error) => {
            console.log(err)
        })

        form.resetFields()

    }

    return (<div>
            <Header/>
            <Flex justify={"center"} style={{paddingTop:"25px"}}>
                <Card type="flex" title={"Добавить задачу"} justify="center" align="middle" >


                    {/*<Button type='primary' size='large' shape='round' onClick={(e) => setCreated(!Createduser)}>Создать</Button>*/}
                    {/*Create User*/}
                    <Form
                        layout='vertical'
                        style={{justifyContent: 'center'}}
                        name="basic"
                        form={form}
                        wrapperCol={{
                            span: '75%',
                        }}
                        style={{
                            width: '325px',
                            verticalAlign: 'middle',
                            maxWidth: '75%',

                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={Sendrequest}
                        // onFinishFailed={}
                        autoComplete="off"
                    >



                    <Form.Item label="Файл TXT">
                        <Form.Item rules={[{required:true,message:"Загрузите txt файл!"}]} name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                            <Upload.Dragger maxCount={1} name="files" fileList={fileList} onChange={onChangeupload} beforeUpload={beforeUpload} multiple={false} customRequest={
                                (info)=>{
                                    console.log("9",info.file)
                                        setFileList([info.file]);
                                    }}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Нажмите или перетащите сюда ваш файл (только txt)</p>
                            </Upload.Dragger>

                        </Form.Item>
                    </Form.Item>


                        <Form.Item
                            wrapperCol={{


                            }}
                        >
                            <Button type="primary" htmlType="submit" size='large'>
                                Добавить
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>

            </Flex>

        </div>
    );

}