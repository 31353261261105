import logo from './logo.svg';
import './App.css';
import {ConfigProvider, Layout, theme} from "antd";
import {AuthProvider} from "./funcs/Authcontext";
import {Header} from "antd/es/layout/layout";
import {Route, Routes} from "react-router-dom";
import PrivateRoutes1 from "./funcs/CheckAuth";
import Authpage from "./pages/Authpage";
import Mainpage from "./pages/Mainpage";
import Addtask from "./pages/Addtask";
import {useState} from "react";



function App() {

  return (
      <ConfigProvider
          theme={{
            algorithm: theme.darkAlgorithm,
            "token": {

              "colorPrimary": "#a017ff",
              "colorInfo": "#a017ff",
              "fontSize": 16
            },

          }}
      >
        <Layout style={{minHeight:"100vh"}}>
          <AuthProvider>
            <Routes>
              <Route path="/login" element={<Authpage/>}/>

              <Route element={<PrivateRoutes1 />}>
                <Route exact path='/*' element={<Mainpage/>}/>
                <Route exact path='/addtask' element={<Addtask/>}/>
                {/*<Route path='/users' element={<Users/>}/>*/}
                {/*<Route path='/transport' element={<Transport/>}/>*/}
              </Route>
            </Routes>

          </AuthProvider>
        </Layout>
      </ConfigProvider>
  );
}

export default App;
