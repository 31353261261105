import {Component, useEffect, useState} from "react";

import {Button, Card, Flex, Pagination, Row, Typography, Space, Progress, message} from "antd";
import Apiurl from "../ConstURL";

import {
    CheckOutlined, CloseOutlined,
    FileExcelOutlined, MinusOutlined,
} from '@ant-design/icons';

import Header from "../components/Header";
import {useAuth} from "../funcs/Authcontext";



export default function Mainpage() {

    const [tasks, settasks] = useState([])
    const [taskscount, settaskscount] = useState(0)
    const { Text, Link } = Typography;
    const [pagenum, set_pagenum] = useState(1)


    function DeleteElementYess(id) {
        const idx = tasks.findIndex((el) => el.Id === id)
        let newusers = tasks.slice()
        newusers[idx].DeleteYesorNo = !newusers[idx].DeleteYesorNo
        settasks(newusers)

        fetch(Apiurl + "/task/delete/"+id+"?" + new URLSearchParams({
            token: localStorage.getItem("token"),
        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.Status){
                    let newusers = tasks.slice()
                    newusers.splice(idx,1)
                    settasks(newusers)
                    message.success("Задача удалена!")
                }else {
                    message.error("Задача не удалена!")
                }

            })




    }
    function DeleteElementYesorNo(id) {

        const idx = tasks.findIndex((el) => el.Id === id)
        let newusers = tasks.slice()
        newusers[idx].DeleteYesorNo = !newusers[idx].DeleteYesorNo
        settasks(newusers)


    }
    const CancelParsing = (taskid) => {
        fetch(Apiurl + "/task/cancel/"+taskid+"?" + new URLSearchParams({
            token: localStorage.getItem("token")
        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.Status){
                    message.success("Задача "+taskid +" отменена")
                }else {
                    message.error("Задача "+taskid +" не отменена")
                }

            })

        fetch(Apiurl + "/task/get?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            skip: (pagenum-1)*10,
            limit: 10
        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data.Tasks)
                settaskscount(data.Allcount)
                settasks(data.Tasks)
            })
    };

    const onChangePage = (page) => {

        set_pagenum(page)



        fetch(Apiurl + "/task/get?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            skip: (page-1)*10,
            limit: 10
        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data.Tasks)
                settaskscount(data.Allcount)
                settasks(data.Tasks)
            })

    };

    useEffect(()=>{
        fetch(Apiurl + "/task/get?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            skip: (pagenum-1)*10,
            limit: 10
        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data.Tasks)
                settaskscount(data.Allcount)
                settasks(data.Tasks)
            })
    },[pagenum]);

    useEffect(() => {

        const timer = setInterval(() => {
            fetch(Apiurl + "/task/get?" + new URLSearchParams({
                token: localStorage.getItem("token"),
                skip: (pagenum-1)*10,
                limit: 10
            }))
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    tasks.forEach((el)=>{
                        if (el.DeleteYesorNo){
                            const idx = data.Tasks.findIndex((element) => element.Id === el.Id)
                            data.Tasks[idx].DeleteYesorNo=true;
                        }


                    })

                    settaskscount(data.Allcount)
                    settasks(data.Tasks)
                })
        }, 2500);

        // очистка интервала
        return () => clearInterval(timer);
    },[tasks,pagenum]);

    return (<div>
        <Header/>
        <Flex justify={"center"} style={{paddingTop:"25px"}}>
            <Space type="flex" size='large' justify="center" align="middle" direction="vertical" style={{padding: "20px"}}>

                {tasks.map((task, index) => (
                    <Card title={"ID: "+task.Id} style={{width:"90vw"}} extra={
                        (task.Task_status==="done" || task.Task_status==="cancelled" || task.Task_status==="err") && (task.DeleteYesorNo ? <Space ><Button shape={"circle"} onClick={(e) => DeleteElementYess(task.Id)} icon={<CheckOutlined />}></Button> <Button type={"primary"} shape={"circle"} icon={<CloseOutlined />} danger onClick={(e) =>DeleteElementYesorNo(task.Id)}></Button></Space> : <Button   shape={"circle"} icon={<MinusOutlined />} onClick={(e) =>DeleteElementYesorNo(task.Id)}></Button>)
                    }>
                        {/*<div>ID: {task.Id}</div>*/}
                        <div style={{paddingTop:"0px"}}>Запустил: {task.Who_started_username}</div>
                        <div style={{paddingTop:"2px"}}>Кол. уникальных строк: {task.All_id_count}</div>
                        <div style={{paddingTop:"20px"}}>Статус:
                            {task.Task_status==="done" && <Text style={{paddingLeft:"5px"}} type="success">выполнен</Text>}
                            {task.Task_status==="running" && <Text style={{paddingLeft:"5px"}}type="secondary">выполняется</Text>}
                            {task.Task_status==="generate_excel" && <Text style={{paddingLeft:"5px"}}type="warning">генерируется excel</Text>}
                            {task.Task_status==="wait" && <Text style={{paddingLeft:"5px"}}type="warning">в очереди</Text>}
                            {task.Task_status==="cancelled" && <Text style={{paddingLeft:"5px"}}type="danger">отменен</Text>}
                            {task.Task_status==="err" && <Text style={{paddingLeft:"5px"}}type="danger">ошибка</Text>}
                        </div>
                        {task.Task_status==="running" && <div style={{paddingTop:"5px"}}>Кол. обработанных строк: {task.Parsed_id_count}</div>}
                        {task.Task_status==="done" && <div style={{paddingTop:"5px", paddingBottom:"25px"}}>Выполнен за {task.Time_spent} сек.</div>}
                        {task.Task_status==="done" && <Button rel="noreferrer" htmlType={"a"} target="_blank" href={Apiurl+"/task/get/excel/"+task.Id.split(":")[1]+"?token="+localStorage.getItem("token")} filename download={"test"+".xlsx"} type={"primary"} size={"large"} icon={<FileExcelOutlined />} style={{paddingTop:"5px"}}>Скачать</Button>}

                        {task.Task_status==="running" && <Progress percent={task.Percent} status="active" />}
                        {(task.Task_status==="running" || task.Task_status==="wait") && <Button onClick={(e) => CancelParsing(task.Id)} style={{marginTop:"15px"}} type={"primary"} danger>Отменить парсинг</Button>}

                    </Card>
                ))}
            </Space>


        </Flex>
        <Flex justify={"center"} style={{padding: "45px"}}>
            <Pagination current={pagenum} total={taskscount} showSizeChanger={false} onChange={onChangePage}/>;
        </Flex>

        </div>
    );

}